import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBrandPost } from "../../types/IBrand.type";
// import { IBrandCategory, IBrandCategoryPost } from "../../types/IBrandCategory.type";
import BrandService from "../services/brands.endpoints";
import { AppDispatch, RootState } from "../store";


export interface IBrandCategory {
  id:number
  name:string
  category:{
    id:number,
    name:string
  }
}

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  brands: IBrandCategory[];
}

const initialState: IInitialState = {
  state: "INACTIVE",
  brands: [],
};

export const getBrandsAsync = createAsyncThunk(
  "brand/getBrands",
  async () => {
    const response = await BrandService.getAllBrands();
    return response;
  }
);

export const postNewBrandAsync = createAsyncThunk(
  "brand/postNewBrand",
  ({ brand,category_id, token }: { brand: IBrandPost, category_id: number, token: string }) => {
    const result = BrandService.postNewBrand(brand, category_id,token);
    return result;
  }
);

export const updateBrandAsync = createAsyncThunk(
  "brand/updateBrand",
  ({ brand, category_id, token }: { brand: IBrandPost, category_id: number, token: string }) => {

    const result = BrandService.upadateBrand(brand, category_id,token);
    
    return result;
  }
);

export const deleteBrandAsync = createAsyncThunk(
  "brand/deleteBrand",
  ({id, category_id, token }: { id: number, category_id:number, token: string }) => {
    const result = BrandService.deleteBrand(id, category_id ,token);
    return result;
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    cleanAllBrands: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    /* GET BRANDS */
    .addCase(getBrandsAsync.pending, (state) => {
      state.state = "LOADING";
    })
    .addCase(getBrandsAsync.fulfilled, (state, action) => {
      state.state = "INACTIVE";
      state.brands = [...action.payload];
    })
    .addCase(getBrandsAsync.rejected, (state) => {
      state.state = "FAIL";
    })

    /* POST BRANDS */
      .addCase(postNewBrandAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(postNewBrandAsync.fulfilled, (state, action) => {
const payload = action.payload
        const brand: IBrandCategory= {id:payload.id, name:payload.name, category:{id:payload.category_id, name:""}}
        
        state.state = "INACTIVE";
        state.brands = [...state.brands, brand];
      })
      .addCase(postNewBrandAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* UPDATE BRAND */
      .addCase(updateBrandAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(updateBrandAsync.fulfilled, (state, action) => {
        // const dataUpdated = action.payload;
        const brandState = state.brands;

        const payload = action.payload
        const brandPayload: IBrandCategory= {id:payload.id, name:payload.name, category:{id:payload.category_id, name:""}}
        

        const newBrandState = brandState.map((brand) =>
          brand.id === payload.id ? brandPayload : brand
        );
        state.state = "INACTIVE";
        state.brands = newBrandState;
      })
      .addCase(updateBrandAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* DELETE BRAND */
      .addCase(deleteBrandAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(deleteBrandAsync.fulfilled, (state, action) => {
        const brandDeletedId = action.payload;
        const brandState = state.brands;
        const newBrandState = brandState.filter(
          (brand) => brand.id !== brandDeletedId
        );
        state.state = "INACTIVE";
        state.brands = newBrandState;
      })
      .addCase(deleteBrandAsync.rejected, (state) => {
        state.state = "FAIL";
      });
  },
});

export const { cleanAllBrands } = brandSlice.actions;

export const getBrandsApi =
  () => (dispatch: AppDispatch) => {
    dispatch(cleanAllBrands());
    dispatch(getBrandsAsync());
  };


/** ADMIN **/
export const postNewBrandApi =
  (brand: IBrandPost, category_id: number,  token: string) => (dispatch: AppDispatch) => {
    dispatch(postNewBrandAsync({ brand, category_id , token }));
  };

export const updateBrandApi =
  (brand: IBrandPost, category_id: number, token: string) => (dispatch: AppDispatch) => {
    dispatch(updateBrandAsync({ brand, category_id, token }));
    return brand
  };

export const deleteBrandApi =
  (id: number, category_id: number, token: string) => (dispatch: AppDispatch) => {
    dispatch(deleteBrandAsync({ id, category_id , token }));
  };

export const getBrandState = (state: RootState) => state.brands;

export default brandSlice.reducer;
