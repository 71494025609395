import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../store/hooks";
import { IBrand } from "../../types/IBrand.type";

type SelectTextBrandInputProps = {
  name: string;
  // options: Option[];
  id: number;
  label: string;
  disabled?: boolean;
  defaultValue?: Option;
};

type Option = {
  id: number;
  name: string;
};

const SelectTextBrandInput: FC<SelectTextBrandInputProps> = ({
  name,
  id,
  label,
  disabled,
  defaultValue,
}: SelectTextBrandInputProps) => {
  const { control, setValue } = useFormContext();
  const {
    field: { onChange, value, ref },
    formState: { errors },
  } = useController<Record<string, Option>>({
    name: `${name}` as const,
    // name: name,
    control,
    defaultValue: defaultValue,
  });

  const [options, setOptions] = useState<IBrand[]>([
    {
      id: 0,
      name: "",
    },
  ]);

  const { categories } = useAppSelector((state) => state.categories);

  useEffect(() => {
    if (id !== 0) {
      const selectedCategorie = categories.find((categorySelected) => {
        return categorySelected.id === id;
      });
      selectedCategorie && setOptions(selectedCategorie.brands);
    }
  }, [id]);

  const items = options.map((option, index) => (
    <MenuItem
      value={option.id}
      id={option.id.toString()}
      key={`${index}_${option}`}
    >
      {option.name}
    </MenuItem>
  ));

  return (
    <Box mb={2}>
      <FormControl fullWidth>
        <InputLabel id={`label_select_${label}`}>{label}</InputLabel>
        <Select
          labelId={`label_select_${label}`}
          inputProps={{
            name: { label },
            id: "uncontrolled-native",
          }}
          value={value.id ?? 0}
          label={label}
          onChange={(event) => {
            onChange({
              ...value,
              id: event.target.value,
            });
          }}
          size="small"
          disabled={disabled}
          fullWidth
          error={!!errors[name]?.id}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {items}
        </Select>
        <FormHelperText sx={{ color: "#df4336" }}>{`${
          errors[name]?.id?.message || ""
        }`}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default SelectTextBrandInput;
