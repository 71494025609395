import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { IProduct } from "../../types/IProduct.type";
import { IProductPost } from "../../types/IProductPost.type";
import ProductService from "../services/products.endpoints";
import { AppDispatch, RootState } from "../store";

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  stateNew: "LOADING" | "INACTIVE" | "FAIL";
  products: IProduct[];
  productSelected?: IProduct;
  newProduct: IProduct;
  qtyPage: number;
}

type GetProductsFromApi = {
  byBrand: number | null;
  byCategory: number | null;
  page: number;
};

const defaultValue = {
  brand: {
    id: 0,
    name: "",
  },
  category: {
    id: 0,
    name: "",
  },
  id: 0,
  name: "",
  model: "",
  code: "",
  description: "",
  photos: [],
};

const initialState: IInitialState = {
  state: "INACTIVE",
  stateNew: "INACTIVE",
  qtyPage: 1,
  newProduct: defaultValue,
  productSelected: undefined,
  products: [defaultValue],
};

export const getProductAsync = createAsyncThunk(
  "product/getProduct",
  async ({ byBrand, byCategory, page }: GetProductsFromApi) => {
    const response = await ProductService.getAllProduct(
      byBrand,
      byCategory,
      page
    );
    
    return response;
  }
);

export const getProductByIdAsync = createAsyncThunk(
  "product/getProductById",
  async (id: number) => {
    const response = await ProductService.getProductById(id);

    return response;
  }
);

/** ADMIN **/
export const postNewProductAsync = createAsyncThunk(
  "product/postNewProduct",
  ({ product, token }: { product: IProductPost; token: string }) => {
    const result = ProductService.postNewProduct(product, token);
    return result;
  }
);

export const updateProductAsync = createAsyncThunk(
  "product/updateProduct",
  ({ product, token }: { product: IProductPost; token: string }) => {
    const result = ProductService.upadateProduct(product, token);
    return result;
  }
);

export const deleteProductAsync = createAsyncThunk(
  "product/deleteProduct",
  ({ id, token }: { id: number; token: string }) => {
    const result = ProductService.deleteProduct(id, token);
    return result;
  }
);
/** END ADMIN **/

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    cleanAllProducts: (state) => {
      state.products = [defaultValue];
    },
    cleanNewProduct: (state) => {
      state.newProduct = defaultValue;     
    },
    addImage: (state, action) => {

      const newStateProducts = current(state.products).map((item)=>{
        return item.id === action.payload.id ? {...item, photos: [...item.photos, action.payload.image]} : item 
      })

      state.products = newStateProducts

    },
    setProductSelected: (state, action) => {
      state.productSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* GET PRODUCTS */
      .addCase(getProductAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(getProductAsync.fulfilled, (state, action) => {
        state.state = "INACTIVE";
        state.products = action.payload.results;
        state.qtyPage = action.payload.qty_pages;
      })
      .addCase(getProductAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* GET PRODUCTS BY ID */
      .addCase(getProductByIdAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(getProductByIdAsync.fulfilled, (state, action) => {
        state.state = "INACTIVE";
        
        state.productSelected = action.payload;
      })
      .addCase(getProductByIdAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* POST PRODUCTS */
      .addCase(postNewProductAsync.pending, (state) => {
        state.stateNew = "LOADING";
      })
      .addCase(postNewProductAsync.fulfilled, (state, action) => {
        state.newProduct = action.payload.result;
        state.products = [...state.products, action.payload.result];
        state.stateNew = "INACTIVE";
      })
      .addCase(postNewProductAsync.rejected, (state) => {
        state.stateNew = "FAIL";
      })
      // .addCase(postNewProductAsync.pending, (state) => {
      //   state.state = "LOADING";
      // })
      // .addCase(postNewProductAsync.fulfilled, (state, action) => {
      //   state.newProduct = action.payload.result;
      //   state.products = [...state.products, action.payload.result];
      //   state.state = "INACTIVE";
      // })
      // .addCase(postNewProductAsync.rejected, (state) => {
      //   state.state = "FAIL";
      // })

      /* UPDATE PRODUCT */
      .addCase(updateProductAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        const dataUpdated = action.payload;
        const productState = state.products;
        const newProductState = productState.map((product) =>
          product.id === dataUpdated.id ? dataUpdated : product
        );
        state.products = newProductState;
        state.state = "INACTIVE";
      })
      .addCase(updateProductAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* DELETE PRODUCT */
      .addCase(deleteProductAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        const productDeletedId = action.payload;
        const productState = state.products;
       const newProductState = productState.filter(
         (product) => 
        product.id !== productDeletedId

      );
        
        state.state = "INACTIVE";
        state.products = newProductState;
      })
      .addCase(deleteProductAsync.rejected, (state) => {
        state.state = "FAIL";
      });
  },
});

export const { cleanAllProducts, cleanNewProduct,addImage, setProductSelected } =
  productSlice.actions;

export const getProductsFromApi =
  ({ byBrand, byCategory, page }: GetProductsFromApi) =>
  (dispatch: AppDispatch) => {
    dispatch(cleanAllProducts());
    dispatch(getProductAsync({ byBrand, byCategory, page }));
  };

export const getProductsByIdFromApi =
  (id: number) => (dispatch: AppDispatch) => {
    dispatch(getProductByIdAsync(id));
  };

/** ADMIN **/
export const postNewProductApi =
  (product: IProductPost, token: string) => (dispatch: AppDispatch) => {
    dispatch(postNewProductAsync({ product, token }));
  };

export const updateProductApi =
  (product: IProductPost, token: string) => (dispatch: AppDispatch) => {
    dispatch(updateProductAsync({ product, token }));
  };

export const deleteProductApi =
  (id: number, token: string) => (dispatch: AppDispatch) => {

    dispatch(deleteProductAsync({ id, token }));
  };

/** END ADMIN **/

export const getProductState = (state: RootState) => state.products;

export default productSlice.reducer;
