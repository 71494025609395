import { useEffect, useState } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export interface State extends SnackbarOrigin {
  open: boolean;
}

export default function SnackbarComponent({
  text,
  openSnack,
  id,
}: {
  text: string;
  openSnack: "LOADING" | "INACTIVE" | "FAIL";
  id: number;
}) {
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { open } = state;

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };

  useEffect(() => {
    setState({ ...state, open: openSnack !== "LOADING" });
  }, [openSnack]);

  return (
    <>
      {openSnack === "INACTIVE" && (
        <Snackbar
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
          key={id}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            key={id}
            variant="filled"
          >
            {text}
          </Alert>
        </Snackbar>
      )}
      {openSnack === "FAIL" && (
        <Snackbar open={open} autoHideDuration={3000} key={id}>
          <Alert
            key={id}
            severity="error"
            variant="filled"
            onClose={handleClose}
          >
            Ha ocurrido otro error
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
