import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILogin } from "../../types/ILogin.type";
import LoginService from "../services/auth.endpoints";
import { AppDispatch, RootState } from "../store";

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  token: string;
  role: string;
  isValidUser: boolean;
}

const initialState: IInitialState = {
  state: "INACTIVE",
  role: "",
  token: "",
  isValidUser: false,
};

export const postLoginAsync = createAsyncThunk(
  "data/postLogin",
  async (data: ILogin) => {
    const response = await LoginService.postLogin(data);

    return response;
  }
);

export const postValidateUserAsync = createAsyncThunk(
  "data/validateUser",
  async (token: string) => {
    const response = await LoginService.postCheckValidhUser(token);

    return response;
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    cleanAllAuthData: (state) => {
      state.isValidUser = false;
      state.state = "INACTIVE";
      state.token = "";
      state.role = "";

      localStorage.clear();
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* LOGIN */
      .addCase(postLoginAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(postLoginAsync.fulfilled, (state, action) => {
        const token = action.payload.token;
        const role = action.payload.role;

             if (token) {
          localStorage.setItem("token", token);
          state.token = token;
          state.role = role;

        } else {
          localStorage.clear();
        }
        state.state = "INACTIVE";
      })
      .addCase(postLoginAsync.rejected, (state) => {
        state.state = "FAIL";
      })
      /* VALIDATE USER */
      .addCase(postValidateUserAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(postValidateUserAsync.fulfilled, (state, action) => {
        const token = action.payload.token;

        state.state = "INACTIVE";

        if (token === "Credenciales validas") {
          state.isValidUser = true;
        } else {
          state.isValidUser = false;
        }
      })
      .addCase(postValidateUserAsync.rejected, (state) => {
        state.state = "FAIL";
        state.isValidUser = false;
      });
  },
});

export const { cleanAllAuthData, updateToken } = dataSlice.actions;

export const postLoginApi = (data: ILogin) => (dispatch: AppDispatch) => {
  dispatch(cleanAllAuthData());
  dispatch(postLoginAsync(data));
};

export const postCheckTokenApi = (token: string) => (dispatch: AppDispatch) => {
  dispatch(postValidateUserAsync(token));
};

export const getDataState = (state: RootState) => state.data;

export default dataSlice.reducer;
