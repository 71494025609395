import axios, { AxiosResponse } from "axios";
import { ILogin } from "../../types/ILogin.type";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;


const postLogin = async (data: ILogin) => {
  const res = await axios
    .post(`${API_ENDPOINT}api/admin/login`, { admin_user: data })
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    })
  return res;
};

const postCheckValidhUser = async (token: string) => {
  const res = await axios
    .post(`${API_ENDPOINT}api/admin/check_auth`, { token: token })
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    })

    return res;
};

const LoginService = {
  postLogin,
  postCheckValidhUser,
};
export default LoginService;
