import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { setProductSelected } from "../../store/slices/products.slice";
import { IProduct } from "../../types/IProduct.type";
import ImageAlternative from "../ImageAlternative/ImageAlternative.component";
import styles from "./Card.module.css";

const Card: FC<IProduct> = (product) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div
      className={styles.container}
      onClick={() => {
        dispatch(setProductSelected(product));
        navigate(`/producto/${product.id}`);
      }}
    >
      <div className={styles.info}>
        <div className={styles.productPhoto}>
          {product.photos[0]?.image?.url ? (
            <img
              className={styles.photo}
              src={product.photos[0].image.medium.url}
              alt={product.name}
            />
          ) : (
            <ImageAlternative />
          )}
          <p className={styles.typeLabel}>{product.category.name}</p>
        </div>
        <h2 className={styles.name}>{product.name}</h2>
        <p className={styles.model}>
          Modelo: <span>{product.model}</span>
        </p>
        <p className={styles.brand}>
          Marca: <span>{product.brand?.name}</span>
        </p>
      </div>
      <p className={styles.code}>
        Código: <span>{product.code}</span>
      </p>
    </div>
  );
};

export default Card;
