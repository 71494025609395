import imageEmpry from "../../assets/product_default_image.png";
import styles from "./ImageAlternative.module.css";

const ImageAlternative = () => {
  return (
    <div className={styles.container}>
      <img src={imageEmpry} alt="empty" className={styles.image} />
    </div>
  );
};

export default ImageAlternative;
