import styles from "./HeaderDesktop.module.css";
import Logo from "../../../assets/logo.svg";
import { useAppSelector } from "../../../store/hooks";
import ButtonWhatsapp from "../../ButtonWhastsapp/ButtonWhatsapp.component";
import NavLinks from "../../NavLinks/NavLinks.component";
import { useNavigate } from "react-router-dom";

export const HeaderDesktop = () => {
  let navigate = useNavigate();

  const { data } = useAppSelector((state) => state.data);

  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <img
          src={Logo}
          alt="logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <div className={styles.containerLinks}>
          <div className={styles.containerButton}>
            {data?.telephones[0]?.number ? (
              <ButtonWhatsapp
                title={data?.telephones[0]?.title}
                number={data?.telephones[0]?.number}
              />
            ) : (
              <></>
            )}
            {data?.telephones[1]?.number ? (
              <ButtonWhatsapp
                title={data?.telephones[1]?.title}
                number={data?.telephones[1]?.number}
              />
            ) : (
              <></>
            )}
          </div>
          <NavLinks />
        </div>
      </div>
      <div className={styles.header}></div>
    </header>
  );
};
