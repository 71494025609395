import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormSchema } from "./ProductFormSchema";
import { IProductPost } from "../../../types/IProductPost.type";
import styles from "./ProductForm.module.css";
import ControlledTextInput from "../../ControlledTextInput/ControlledTextInput.compontent";
import SelectTextInput from "../../SelectTextInput/SelectTextInput.compontent";
import { useAppSelector } from "../../../store/hooks";
import { IProduct } from "../../../types/IProduct.type";
import LoadingButtonComponent from "../../Buttons/SaveRequest/SaveRequest.component";
import TextareaInput from "../../TextareaInput/TextareaInput.compontent";
import SelectTextBrandInput from "../../SelectTextInputBrand/SelectTextInputBrand.compontent";
import SnackbarComponent from "../../Snackbar/Snackbar.component";

type Props = {
  handleOnSubmit: (product: IProductPost) => void;
  product: IProduct;
  state: "LOADING" | "INACTIVE" | "FAIL";
  title?: string;
  disabled?: boolean;
  cleanForm?: boolean;
  setCleanForm?: Dispatch<SetStateAction<boolean>>;
};

export const ProductForm: FC<Props> = ({
  handleOnSubmit,
  product,
  title,
  state,
  disabled,
  cleanForm,
  setCleanForm,
}) => {
  const methods = useForm<IProductPost>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      ...product,
    },
    mode: "onChange",
  });
  const { watch, handleSubmit, reset, control } = methods;

  const { isValid, isSubmitted } = useFormState({
    control,
  });

  const category = watch("category");
  const { categories } = useAppSelector((state) => state.categories);

  const onSubmit = (data: IProductPost) => {
    handleOnSubmit({ ...data, brand_id: data.brand.id });
  };

  useEffect(() => {
    if (cleanForm === true) {
      reset();
      setCleanForm && setCleanForm(false);
    }
  }, [cleanForm]);

  return (
    <>
      <div
        key={`form_product_${product.id}`}
        className={
          disabled ? `${styles.container} ${styles.disabled}` : styles.container
        }
      >
        {title && <h3 className={styles.title}>{title}</h3>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <div className={styles.content}>
              <div>
                <ControlledTextInput name="name" label="Nombre" />
                <ControlledTextInput name="model" label="Modelo" />
                <ControlledTextInput name="code" label="Código" />
              </div>
              <div>
                <SelectTextInput
                  name="category"
                  options={categories}
                  label="Categorias"
                />
                <SelectTextBrandInput
                  name="brand"
                  id={category.id}
                  disabled={category.id === 0}
                  label="Marca"
                />
              </div>
              <div>
                <TextareaInput name="description" label="Descripción" />
              </div>
            </div>
          </FormProvider>
          <div className={styles.button}>
            <LoadingButtonComponent
              text={"GUARDAR"}
              state={state}
              disable={isValid === false}
            />
          </div>
        </form>
      </div>
      {isSubmitted && (
        <SnackbarComponent
          id={product.id}
          openSnack={state}
          text={"Se ejecuto correctamente"}
        />
      )}
    </>
  );
};

export default ProductForm;
