import Pagination from "@mui/material/Pagination";
import React, { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

type Prop = {
  color?: string;
};

const PaginationComponent: FC<Prop> = ({ color }) => {
  const qtyPage = useAppSelector((state) => state.products.qtyPage);
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") as string);

  useEffect(() => {
    const currentPage = page ? page : 1;
    searchParams.set("page", `${currentPage}`);
    setSearchParams(searchParams);
  }, [page]);

  return (
    <Pagination
      page={page}
      onChange={(event: React.ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", `${page}`);
        setSearchParams(searchParams);
      }}
      count={qtyPage ? qtyPage : 1}
    />
  );
};

export default PaginationComponent;
