import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./SlideImages.css";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSlidersApi } from "../../store/slices/sliders.slice";
import { useResize } from "../hooks/useResize";

const SlideImages = () => {
  const isMobile = useResize();
  const sliders = useAppSelector((state) => state.sliders.sliders);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSlidersApi());
  }, []);

  return (
    <Swiper
      spaceBetween={30}
      effect={"fade"}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      loop={true}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      className="mySwiper"
    >
      {isMobile < 765 &&
        sliders.mobile.map((image) => {
          return (
            <SwiperSlide>
              <img src={image.image.url} alt="imagen" />
            </SwiperSlide>
          );
        })}
      {isMobile >= 765 &&
        isMobile < 1200 &&
        sliders.tablet.map((image) => {
          return (
            <SwiperSlide>
              <img src={image.image.url} alt="imagen" />
            </SwiperSlide>
          );
        })}
      {isMobile > 1201 &&
        sliders.desktop.map((image) => {
          return (
            <SwiperSlide>
              <img src={image.image.url} alt="imagen" />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default SlideImages;
