import styles from "./Footer.module.css";
import Logo from "../../assets/logo.svg";
import { useAppSelector } from "../../store/hooks";
import NavLinks from "../NavLinks/NavLinks.component";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();

  const { data } = useAppSelector((state) => state.data);

  return (
    <footer className={styles.container}>
      <div className={styles.firstColumn}>
        <img
          src={Logo}
          alt="logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <div className={styles.dataContainer}>
          <div className={styles.dataContact}>
            <p>
              {data?.telephones[0]?.title}:
              <span> {data?.telephones[0]?.number}</span>
            </p>
            <p>
              {data?.telephones[1]?.title}:
              <span> {data?.telephones[1]?.number}</span>
            </p>
            <p>{data?.street} - Rosario, Santa Fe</p>
            <p>{data?.email}</p>
          </div>
        </div>
      </div>
      {/* {isPhone >= 765 && ( */}
      <div className={styles.secondColumn}>
        <div className={styles.links}>
          <NavLinks color="white" />
        </div>
        <div className={styles.rightsAndDevleoper}>
          <p>Todos los derechos reservados desde 2022</p>
          <p>
            Diseño del sitio:
            <span>
              <a
                className={styles.dgweb}
                href="https://dgwebrosario.com.ar/"
                target="_blanck"
                rel="noopener noreferrer"
              >
                DgWeb.Rosario
              </a>
            </span>
          </p>
        </div>
      </div>
      {/* )} */}
    </footer>
  );
};

export default Footer;
