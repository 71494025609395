import React, { useEffect } from "react";
import styles from "./HeaderAdmin.module.css";
import Logo from "../../assets/logo.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { cleanAllAuthData } from "../../store/slices/authAdmin.slice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import NavLinksAdmin from "../NavLinksAdmin/NavLinksAdmin.component";

const HeaderAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isValidUser, token } = useAppSelector((state) => state.authAdmin);

  const logOut = () => {
    dispatch(cleanAllAuthData());
    !isValidUser && navigate("/administracion/login", { replace: true });
  };

  useEffect(() => {
    !isValidUser && navigate("/administracion/login", { replace: true });
  }, [isValidUser]);

  return (
    <header className={styles.containerHeader}>
      <div className={styles.container}>
        <img src={Logo} alt="logo" className={styles.logo} />
        <div className={styles.containerLinks}>
          <button className={styles.button} onClick={logOut}>
            SALIR
            <span>
              <FontAwesomeIcon icon={faSignOut} />
            </span>
          </button>
          <NavLinksAdmin />
        </div>
      </div>
      <div className={styles.header}></div>
    </header>
  );
};

export default HeaderAdmin;
