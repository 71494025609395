import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterType } from "../SectionFilters.component";
import styles from "./Breadcrumbs.module.css";

type Props = {
  filter: FilterType;
};

const Breadcrumbs: FC<Props> = ({ filter }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleAllCategories = () => {
    searchParams.set("page", `1`);
    searchParams.delete("tipo");
    searchParams.delete("marca");

    setSearchParams(searchParams);
  };

  const handleCategory = () => {
    searchParams.set("page", `1`);
    searchParams.set("tipo", `${filter.category.id}_${filter.category.name}`);
    searchParams.delete("marca");

    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <p className={styles.breadcrumbs}>
        <span className={styles.item} onClick={handleAllCategories}>
          Repuestos
        </span>
        {filter.category.name && (
          <span className={styles.item} onClick={handleCategory}>
            {/* <span className={styles.item}> */}
            <FontAwesomeIcon icon={faChevronRight} className={styles.chevron} />
            {filter.category.name}
          </span>
        )}
        {filter.brand.name && (
          <span className={styles.item}>
            <FontAwesomeIcon icon={faChevronRight} className={styles.chevron} />
            {filter.brand.name}
          </span>
        )}
      </p>
    </div>
  );
};

export default Breadcrumbs;
