import axios, { AxiosResponse } from "axios";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;

interface IHeaders {
  headers: {
    "Content-Type": string;
    Authorization: string;
  };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data;",
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const getSliders = async () => {
  const response = await axios.get(`${API_ENDPOINT}api/sliders`);
 
  return response.data;
};

const postNewSliders = async (sliders: any, token: string) => {
  const config: IHeaders = configHeaders(token);
  
  const res = await axios
    .post(`${API_ENDPOINT}admin/sliders`,  sliders, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });    
  return res;
};

const deleteSliders = async (id: number, token: string) => {
  const config: IHeaders = configHeaders(token);
  const res = await axios.delete(`${API_ENDPOINT}admin/sliders/${id}`, config);

  return res.data;
};

const SlidersService = {
  getSliders,
  postNewSliders,
  deleteSliders,
};
export default SlidersService;
