import * as yup from "yup";

export const FormSchema = yup.object({
    name: yup.string().required("*Campo requerido"),
    model: yup.string().required("*Campo requerido"),
    code: yup.string().required("*Campo requerido"),
    description: yup.string().required("*Campo requerido"),
    category: yup.object({
        id: yup.number().min(1,"*Campo requerido"),    
       }),
    brand: yup.object({
        id: yup.number().min(1,"*Campo requerido"),
      })
}).required();
