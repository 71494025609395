import React, { FC, useEffect, useState } from "react";
import IconGuarantee from "../../assets/guarantee.svg";
import IconTechinicalSupport from "../../assets/technical-support.svg";
import IconProducts from "../../assets/products.svg";

import styles from "./CardSectionGuarantee.module.css";

interface CardSectionGuaranteeProps {
  icon: string;
  title: string;
  description: string;
}

const CardSectionGuarantee: FC<CardSectionGuaranteeProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className={styles.container}>
      <img
        src={
          icon === "IconGuarantee"
            ? IconGuarantee
            : icon === "IconProducts"
            ? IconProducts
            : IconTechinicalSupport
        }
        alt={`ICONO ${title}`}
        width="50px"
        className={styles.icon}
      />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default CardSectionGuarantee;
