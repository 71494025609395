import { FC } from "react";
import styles from "./Button.module.css";

type ButtonProps = {
  buttonName: string;
  style: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
};

const Button: FC<ButtonProps> = ({
  type = "button",
  style,
  buttonName,
  onClick,
}) => {
  const STYLES = ["send", "login", "add", "modify"];
  const stylePred = STYLES.find((item) => style === item);
  const buttonStyle = style === stylePred ? style : "send";

  return (
    <button
      className={`${styles[buttonStyle]} ${styles.button}`}
      type={type}
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default Button;
