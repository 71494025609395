import { faClose, faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs.component";
import { Filter } from "./Filter.component";
import styles from "./SectionFiltersMobile.module.css";

export type FilterType = {
  brand: {
    id: number | null;
    name: string | null;
  };
  category: {
    id: number | null;
    name: string | null;
  };
};

const SectionFiltersMobile = () => {
  const [open, setOpen] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const { categories } = useAppSelector((state) => state.categories);

  const defaultState = {
    brand: {
      id: null,
      name: null,
    },
    category: {
      id: null,
      name: null,
    },
  };
  const handleAllCategoriesSelected = () => {
    searchParams.delete("marca");
    searchParams.delete("tipo");
    setFilter(defaultState);
    searchParams.set("page", `1`);
    setSearchParams(searchParams);
  };

  const [filter, setFilter] = useState<FilterType>(defaultState);

  const brandParam = searchParams.get("marca");
  const categoryParam = searchParams.get("tipo");

  const brand = brandParam ? parseInt(brandParam?.split("_")[0]) : null;
  const category = categoryParam
    ? parseInt(categoryParam?.split("_")[0])
    : null;
  const brandName = brandParam ? brandParam?.split("_")[1] : null;
  const categoryName = categoryParam ? categoryParam?.split("_")[1] : null;

  useEffect(() => {
    setFilter({
      brand: {
        id: brand,
        name: brandName,
      },
      category: {
        id: category,
        name: categoryName,
      },
    });
  }, [brandParam]);

  useEffect(() => {
    setFilter({
      brand: {
        id: brand,
        name: brandName,
      },
      category: {
        id: category,
        name: categoryName,
      },
    });
  }, [categoryParam]);

  //---------

  useEffect(() => {
    if (filter.category.id !== null && filter.brand.id === null) {
      searchParams.delete("marca");
      searchParams.set("tipo", `${filter.category.id}_${filter.category.name}`);
    }
    if (filter.category.id !== null && filter.brand.id !== null) {
      searchParams.set("tipo", `${filter.category.id}_${filter.category.name}`);
      searchParams.set("marca", `${filter.brand.id}_${filter.brand.name}`);
    }
    setSearchParams(searchParams);
  }, [filter.category, filter.brand]);

  const handleCategorySelected = (id: number, categoryName: string) => {
    setFilter({
      brand: {
        id: null,
        name: null,
      },
      category: {
        id: id,
        name: categoryName,
      },
    });
    searchParams.set("page", `1`);
    setSearchParams(searchParams);
  };

  const handleBrandSelected = (
    id: number,
    brandName: string,
    idCategory: number,
    categoryName: string
  ) => {
    setFilter({
      brand: {
        id: id,
        name: brandName,
      },
      category: {
        id: idCategory,
        name: categoryName,
      },
    });
    searchParams.set("page", `1`);
    setSearchParams(searchParams);
  };

  const listItems = (
    <List sx={{ minWidth: { xs: "220px", sm: "300px" }, paddingTop: "0px" }}>
      {/* <ListItem key={`category_all`} disablePadding> */}
      <div className={styles.header}>
        <p className={styles.headerTitle}>FILTROS</p>
        <div className={styles.buttonClose} onClick={() => setOpen(false)}>
          <FontAwesomeIcon className={styles.iconClose} icon={faClose} />
        </div>
      </div>
      {/* </ListItem> */}
      <Divider />
      <ListItem key={`category_all`} disablePadding>
        <ListItemButton onClick={() => handleAllCategoriesSelected()}>
          <ListItemText
            primary={"Ver todos"}
            sx={{
              textTransform: "uppercase",
            }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      {categories?.map((category) => (
        <Filter
          category={category}
          filter={filter}
          handleCategorySelected={handleCategorySelected}
          handleBrandSelected={handleBrandSelected}
        />
      ))}
    </List>
  );

  return (
    <div className={styles.container}>
      <Breadcrumbs filter={filter} />
      <React.Fragment key="right">
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpen(true)}
        >
          FILTROS
          <FontAwesomeIcon icon={faSliders} className={styles.iconFilter} />
        </Button>
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
          {listItems}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SectionFiltersMobile;
