import "./App.css";
import { Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { getDataFromApi } from "./store/slices/business.slice";
import { useAppDispatch } from "./store/hooks";
import { Footer, Header, HeaderAdmin } from "./componets";
// import {
//   About,
//   Admin,
//   CategoriesSection,
//   BusinessSection,
//   ProductsSection,
//   Contact,
//   Home,
//   Login,
//   Product,
//   Products,
// } from "./Pages";
import TechnicalSupport from "./Pages/TechnicalSuppot/TechnicalSupport.page";
import SlidesSectionPage from "./Pages/Admin/SlidesSection/SlidesSection.page";
import LoadingComponent from "./componets/LoadingComponent/LoadingComponent.component";

const About = lazy(() => import("./Pages/About/About.page"));
const Admin = lazy(() => import("./Pages/Admin/Admin.page"));
const CategoriesSection = lazy(
  () => import("./Pages/Admin/CategoriesSection/CategoriesSection.page")
);
const BusinessSection = lazy(
  () => import("./Pages/Admin/BusinessSection/BusinessSection.page")
);
const ProductsSection = lazy(
  () => import("./Pages/Admin/ProductsSection/ProductsSection.page")
);
const Contact = lazy(() => import("./Pages/Contact/Contact.page"));
const Home = lazy(() => import("./Pages/Home/Home.page"));
const Login = lazy(() => import("./Pages/Login/Login.page"));
const Product = lazy(() => import("./Pages/Product/Product.page"));
const Products = lazy(() => import("./Pages/Products/Products.page"));

function Layout() {
  return (
    <div className="Layout">
      <Header />
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/producto/:id" element={<Product />} />
          <Route path="/soporte-tecnico" element={<TechnicalSupport />} />
          <Route path="/gruporyf" element={<About />} />
          <Route path="/contacto" element={<Contact />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

function LayoutAdmin() {
  return (
    <div className="Layout">
      <HeaderAdmin />
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path="/" element={<Admin />} />
          <Route path="/datos" element={<BusinessSection />} />
          <Route path="/categorias" element={<CategoriesSection />} />
          <Route path="/repuestos" element={<ProductsSection />} />
          <Route path="/sliders" element={<SlidesSectionPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDataFromApi());
  }, [dispatch]);

  return (
    <div className="App">
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path="/*" element={<Layout />} />
          <Route path="/administracion/*" element={<LayoutAdmin />} />
          <Route path="/administracion/login" element={<Login />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
