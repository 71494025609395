import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import styles from "./LoadSlice.module.css";
import "sweetalert2/dist/sweetalert2.min.css";
import Modals from "../../../utils/modal";
import { Image } from "./Image.component";
import { ISliders } from "../../../types/ISliders.type";
import {
  deleteSliderApi,
  postNewSliderApi,
} from "../../../store/slices/sliders.slice";

export const LoadSlice = ({
  images,
  tag,
}: {
  tag: string;
  title?: string;
  images?: ISliders[];
}) => {
  const [fileToSave, setFileToSave] = useState<any>([]);

  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.authAdmin.token);

  const loadFiles = (file: any) => {
    const f = new FormData();
    f.append("image", file[0]);
    f.append("tag", tag);

    setFileToSave(f);
  };

  const sendFiles = async () => {
    dispatch(postNewSliderApi(fileToSave, token));
  };

  const deleteFiles = (slide: ISliders) => {
    Modals.deleteModal("¿Desea elminar el archivo?").then((response) => {
      if (response === true) {
        dispatch(deleteSliderApi(slide, token));
      }
    });
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{tag}</h3>

      <form encType="multipart/form-data" action="" className={styles.form}>
        <input
          type="file"
          name="files"
          multiple
          onChange={(e) => loadFiles(e.target.files)}
          className={styles.fieldInput}
        />
        <FontAwesomeIcon
          icon={faFloppyDisk}
          onClick={() => sendFiles()}
          className={styles.icon}
        />
      </form>
      <div className={styles.listStyle}>
        {images?.map((image, index) => {
          return (
            <div key={`image_${index}`}>
              <Image
                image={image.image.url}
                deleteFiles={() => deleteFiles(image)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
