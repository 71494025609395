import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  cleanNewProduct,
  postNewProductApi,
} from "../../../store/slices/products.slice";
import { IProductPost } from "../../../types/IProductPost.type";
import GeneralButton from "../../Buttons/GeneralButton/GeneralButton.component";
import { LoadImage } from "../LoadImage/LoadImage.component";
import { ProductForm } from "../ProductForm/ProductForm.component";
import styles from "./AddProduct.module.css";

const AddProduct = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.authAdmin.token);
  const { newProduct, stateNew } = useAppSelector((state) => state.products);
  const [cleanForm, setCleanForm] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleAdd = (productData: IProductPost) => {
    dispatch(postNewProductApi(productData, token));
    setDisabled(true);
  };

  return (
    <section className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>AGREGAR PRODUCTO</h3>
        <GeneralButton
          disabled={newProduct.id === 0}
          onClick={() => {
            dispatch(cleanNewProduct());
            setCleanForm(true);
            setDisabled(false);
          }}
        >
          <p>
            VACIAR FORMULARIO <FontAwesomeIcon icon={faRotateRight} />
          </p>
        </GeneralButton>
      </div>
      <div className={styles.containerForm}>
        <ProductForm
          title="1.- Completar datos del producto"
          handleOnSubmit={handleAdd}
          product={newProduct}
          cleanForm={cleanForm}
          setCleanForm={setCleanForm}
          disabled={disabled}
          state={stateNew}
        />
        <div className={styles.images}>
          <LoadImage
            productID={newProduct.id}
            cleanForm={cleanForm}
            title="2.- Agregar imagenes del producto"
          />
        </div>
      </div>
    </section>
  );
};

export default AddProduct;
