import Logo from "../../../assets/logo.svg";
import { IconBurger } from "./iconBurger.component";
import styles from "./HeaderBurguer.module.css";
import { useNavigate } from "react-router-dom";

export const HeaderBurguer = () => {
  let navigate = useNavigate();

  return (
    <header className={styles.container}>
      <img
        src={Logo}
        alt="logo"
        className={styles.logo}
        onClick={() => navigate("/")}
      />
      <IconBurger />
    </header>
  );
};
