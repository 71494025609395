import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import ButtonWhatsapp from "../../ButtonWhastsapp/ButtonWhatsapp.component";
import useToggle from "../../hooks/useToggle";
import styles from "./iconBurguer.module.css";

export const IconBurger = () => {
  const { isSelected, toggle } = useToggle();
  const handleBurguerIcon = () => {
    toggle();
  };

  const data = useAppSelector((state) => state.data.data);

  return (
    <div className={styles.hamburgerMenu} onClick={handleBurguerIcon}>
      <div
        className={isSelected ? `${styles.bar} ${styles.animate}` : styles.bar}
      >
        {isSelected && (
          <nav
            className={
              isSelected
                ? `${styles.container} ${styles.open}`
                : styles.container
            }
          >
            <ul className={styles.dropdown}>
              <li className={styles.dropdownItem1}>
                <NavLink to="/">Inicio</NavLink>
              </li>
              <li className={styles.dropdownItem3}>
                <NavLink to="/productos">Repuestos</NavLink>
              </li>
              <li className={styles.dropdownItem2}>
                <NavLink to="/soporte-tecnico">Servicio Tecnico</NavLink>
              </li>

              <li className={styles.dropdownItem4}>
                <NavLink to="/gruporyf">Grupo R&F</NavLink>
              </li>
              <li className={styles.dropdownItem5}>
                <NavLink to="/contacto">Contacto</NavLink>
              </li>
              <li className={styles.dropdownItem6}>
                <ButtonWhatsapp
                  title={data.telephones[0].title}
                  number={data.telephones[0].number}
                />
              </li>
              <li className={styles.dropdownItem7}>
                <ButtonWhatsapp
                  title={data.telephones[1].title}
                  number={data.telephones[1].number}
                />
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};
