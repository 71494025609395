import { useEffect, useState } from "react";

export const useResize = () => {
  const [withScreen, setWithScreen] = useState<number>(0);

  const handleResize = () => {
    setWithScreen(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return withScreen;
};
