import { useResize } from "../hooks/useResize";
import { HeaderDesktop } from "./HeaderDesktop/HeaderDesktop.component";
import { HeaderBurguer } from "./HeaderBurguer/HeaderBurguer.component";

const Header = () => {
  const isPhone = useResize();
  return <>{isPhone <= 765 ? <HeaderBurguer /> : <HeaderDesktop />}</>;
};

export default Header;
