import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { ICategory } from "../../types/ICategory.type";
import CategoryService from "../services/categories.endpoints";
import { AppDispatch, RootState } from "../store";

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  stateDelete: "LOADING" | "INACTIVE" | "FAIL";
   categories: ICategory[];
}

const initialState: IInitialState = {
  state: "INACTIVE",
  stateDelete: "INACTIVE",
  categories: [],
};

export const getCategoriesAsync = createAsyncThunk(
  "category/getCategories",
  async () => {
    const response = await CategoryService.getAllCategories();
    return response;
  }
);

// export const getByIdCategoryAsync = createAsyncThunk(
//   "category/getByIdCategory",
//   async (id: number) => {
//     const response = await CategoryService.getCategoryById(id);
//     return response;
//   }
// );

export const postNewCategoryAsync = createAsyncThunk(
  "category/postNewCategory",
  ({ category, token }: { category: ICategory; token: string }) => {
    const result = CategoryService.postNewCategory(category,token);
  
    return result;
  }
);

export const updateCategoryAsync = createAsyncThunk(
  "category/updateCategory",
  ({ category, token }: { category: ICategory; token: string }) => {

    const result = CategoryService.upadateCategory(category,token);
    return result;
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteCategory",
  ({id, token }: { id: number; token: string }) => {
    const result = CategoryService.deleteCategory(id, token);
   
    return result;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    cleanAllCategorys: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /* GET PRODUCTS */
      .addCase(getCategoriesAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {

        state.state = "INACTIVE";
        state.categories = action.payload;
      })
      .addCase(getCategoriesAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* POST PRODUCTS */
      .addCase(postNewCategoryAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(postNewCategoryAsync.fulfilled, (state, action) => {

      
        state.state = "INACTIVE";
        state.categories = [...state.categories, action.payload];
      })
      .addCase(postNewCategoryAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* UPDATE PRODUCT */
      .addCase(updateCategoryAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(updateCategoryAsync.fulfilled, (state, action) => {

        state.state = "INACTIVE";
      })
      .addCase(updateCategoryAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* DELETE PRODUCT */
      .addCase(deleteCategoryAsync.pending, (state) => {
        state.stateDelete = "LOADING";
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {

            const itemID = action.payload
        const categories = current(state.categories);


       const items =categories.filter((item) => item.id !== itemID)
      return {
        ...state,
        stateDelete: "INACTIVE",
categories: [...items]

      }

          // state.categories = newCategoryState;
      })
      .addCase(deleteCategoryAsync.rejected, (state) => {
        state.stateDelete = "FAIL";
      });
  },
});

export const { cleanAllCategorys } = categorySlice.actions;

export const getCategoriesFromApi = () => (dispatch: AppDispatch) => {
  dispatch(cleanAllCategorys());
  dispatch(getCategoriesAsync());
};

/** ADMIN **/
export const postNewCategoryApi =
  (category: ICategory, token: string) => (dispatch: AppDispatch) => {
    dispatch(postNewCategoryAsync({ category, token }));
  };

export const updateCategoryApi =
  (category: ICategory, token: string) => (dispatch: AppDispatch) => {
    dispatch(updateCategoryAsync({ category, token }));
    return category
  };

export const deleteCategoryApi =
  (id: number, token: string) => (dispatch: AppDispatch) => {
    dispatch(deleteCategoryAsync({ id, token }));
  };

export const getCategoryState = (state: RootState) => state.categories;

export default categorySlice.reducer;
