import axios, { AxiosResponse } from "axios";
import { IBusiness, ITelephone } from "../../types/IBusiness.type";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;

// const access_token = localStorage.getItem("token");

interface IHeaders {
  headers: { "Content-Type": string; Authorization: string };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const getBusiness: () => Promise<IBusiness> = async () => {
  const response = await axios.get(`${API_ENDPOINT}api/businesses`);
  return response.data;
};

/*************/
/*** ADMIN ***/
/*************/

const upadateBusiness = async (data: IBusiness, token: string) => {
  const config: IHeaders = configHeaders(token);

  const res = await axios
    .put(`${API_ENDPOINT}admin/businesses/1`, data, config)
    .then(({ data }: AxiosResponse<any>) => {

      return data.result;
    });
  return res;
};

const upadateTelephone = async (
  id: number,
  data: ITelephone,
  token: string
) => {
  const config: IHeaders = configHeaders(token);

  const res = await axios
    .put(`${API_ENDPOINT}/admin/businesses/1/telephones/${id}`, data, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data.result;
    });
    
  return res;
};

const BusinessService = {
  getBusiness,
  upadateBusiness,
  upadateTelephone,
};
export default BusinessService;
