import styles from "./TechnicalSupport.module.css";
import lgLogo from "../../assets/lg-official.svg";
import servicios from "../../assets/servicios.png";
import { ITechnicalSupport } from "../../types/ITechnicalSupport";
import { splitFunction } from "../../utils/splitFunction";
import { MainTitle } from "../../componets";

const TechnicalSupport = () => {
  const about: ITechnicalSupport = {
    title: "Bienvenido al Servicio Técnico Oficial LG",
    description:
      "Desde Grupo RyF formamos parte del servicio de reparación de electrodomésticos de la marca LG hace más de 15 años. //De esta manera brindamos confianza al momento de realizar todos nuestros trabajos, ya que contamos con repuestos originales y la capacitación adecuada a la hora de ejecutar todas nuestras reparaciones.",
  };
  const data = splitFunction(about.description);

  return (
    <main className={styles.container}>
      <section className={styles.section}>
        <MainTitle>Servicio Técnico</MainTitle>
        <div className={styles.content}>
          <div>
            <h2 className={styles.title}>{about.title}</h2>
            <div className={styles.description}>
              {data.map((item) => (
                <p className={styles.paragraph}>{item}</p>
              ))}
              <p className={styles.paragraph}>
                Además de la marca LG, atendemos gran variedad de marcas tanto
                de heladeras como de lavarropas, ofreciéndole siempre a nuestros
                clientes un servicio completo. Si necesitas realizar una
                reparación o solicitar un presupuesto comunícate con nosotros a
                nuestro número de WhatsApp de Servicio Técnico para realizar una
                visita a tu domicilio y brindarte el servicio que necesitas.
              </p>
            </div>
          </div>
          <img src={lgLogo} alt="logo lg oficial" className={styles.lgLogo} />
        </div>
        <div className="bottom">
          <div className={styles.footerIllustrationBox}>
            <img
              src={servicios}
              alt="footer illustration"
              className={styles.footerIllustration}
            />
            <div className={styles.secondParagraph}>
              <p>Tambien atendemos</p>
              <p>otras marcas como</p>
              <p className={styles.boldText}>Whirpool, Samsung, Electrolux,</p>
              <p className={styles.boldText}>Eslabon de lujo, Dream,</p>
              <p>entre otras.</p>
            </div>
          </div>
          <div className={styles.footerIllustrationBackground}></div>
        </div>
      </section>
    </main>
  );
};

export default TechnicalSupport;
