import axios, { AxiosResponse } from "axios";
import { IProduct } from "../../types/IProduct.type";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;

interface IHeaders {
  headers: {
    "Content-Type": string;
    Authorization: string;
  };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data;",
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const postNewPhoto = async (file: any, token: string, product_id: number) => {
  const config = configHeaders(token);

  const res = await axios.post(
    `${API_ENDPOINT}admin/products/${product_id}/photos/`,
    file,
    config
  );

  return res;
};

const deletePhoto = async (id: number, product_id: number, token: string) => {
  const config = configHeaders(token);

  const res = await axios.delete(
    `${API_ENDPOINT}admin/products/${product_id}/photos/${id}`,
    config
  );
  // .then(({data}: AxiosResponse<any>) => {
  //   return data;
  // })

  return res;
};

// const getProductById = async (id: number) => {
//   const response = await axios.get(`${API_ENDPOINT}product/${id}`);
//   return response.data.result;
// };

const postNewProduct = async (product: IProduct, token: string) => {
  const config: IHeaders = configHeaders(token);
  const res = await axios
    .post(`${API_ENDPOINT}admin/products`, { product: product }, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
  return res;
};

const upadateProduct = async (product: IProduct, token: string) => {
  const config: IHeaders = configHeaders(token);
  const res = await axios
    .put(`${API_ENDPOINT}admin/products/${product.id}`, product, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
  return res;
};

const deleteProduct = async (id: number, token: string) => {
  const config: IHeaders = configHeaders(token);

  await axios.delete(`${API_ENDPOINT}api/products/${id}`, config);
  return id;
};

const PhotoService = {
  postNewPhoto,
  deletePhoto,
};
export default PhotoService;
