import { Box, TextField } from "@mui/material";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

type TextareaInputProps = {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  variant?: "filled" | "outlined" | "standard";
};

const TextareaInput: FC<TextareaInputProps> = ({
  name,
  label,
  defaultValue,
  disabled,
  variant,
}: TextareaInputProps) => {
  const { control, setValue } = useFormContext();
  const {
    field: { onChange, value, ref },
    formState: { errors },
  } = useController<Record<string, string>>({
    name: `${name}` as const,
    control,
    defaultValue: defaultValue,
  });

  return (
    <Box mb={2}>
      <TextField
        onChange={onChange}
        value={value}
        label={label}
        multiline
        fullWidth
        rows={5}
        variant={variant ? variant : "outlined"}
        defaultValue={defaultValue}
        error={!!errors[name]}
        helperText={`${errors[name]?.message || ""}`}
        disabled={disabled}
      />
    </Box>
  );
};

export default TextareaInput;
