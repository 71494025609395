import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavLinks.module.css";

type Prop = {
  color?: string;
};
const NavLinks: FC<Prop> = ({ color }) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <nav
      className={color === "white" ? styles.containerWhite : styles.container}
    >
      <ul>
        <li className={currentRoute === "/" ? styles.active : ""}>
          <NavLink to="/">Inicio</NavLink>
        </li>
        <li className={currentRoute === "/productos" ? styles.active : ""}>
          <NavLink to="/productos">Repuestos</NavLink>
        </li>
        <li
          className={currentRoute === "/soporte-tecnico" ? styles.active : ""}
        >
          <NavLink to="/soporte-tecnico">Servicio Técnico</NavLink>
        </li>

        <li className={currentRoute === "/gruporyf" ? styles.active : ""}>
          <NavLink to="/gruporyf">Grupo R&F</NavLink>
        </li>
        <li className={currentRoute === "/contacto" ? styles.active : ""}>
          <NavLink to="/contacto">Contacto</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavLinks;
