import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  deleteProductApi,
  updateProductAsync,
} from "../../../store/slices/products.slice";
import { IProduct } from "../../../types/IProduct.type";
import { IProductPost } from "../../../types/IProductPost.type";
import Modals from "../../../utils/modal";
import { LoadImage } from "../LoadImage/LoadImage.component";
import { ProductForm } from "../ProductForm/ProductForm.component";
import styles from "./EditProduct.module.css";

const EditProduct = ({ product }: { product: IProduct }) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.authAdmin.token);
  const state = useAppSelector((state) => state.products.state);

  const handleUpdate = (productData: IProductPost) => {
    dispatch(updateProductAsync({ product: productData, token: token }));
  };

  const deleteFiles = () => {
    Modals.deleteModal("¿Desea borrar este producto?").then((response) => {
      if (response === true) {
        dispatch(deleteProductApi(product.id, token));
      }
    });
  };

  return (
    <section className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{product.name}</h3>
        <button
          className={styles.fileIcons}
          onClick={deleteFiles}
          type="button"
        >
          <FontAwesomeIcon icon={faTrashCan} className={styles.iconTrash} />
        </button>
      </div>
      <div className={styles.containerForm}>
        <ProductForm
          handleOnSubmit={handleUpdate}
          product={product}
          state={state}
        />
        <div className={styles.images}>
          <LoadImage productID={product.id} images={product.photos} />
        </div>
      </div>
    </section>
  );
};

export default EditProduct;
