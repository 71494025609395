import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBusiness, ITelephone } from "../../types/IBusiness.type";
import BusinessService from "../services/business.endpoints";
import { AppDispatch, RootState } from "../store";

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  data: IBusiness;
  dataTelephone: IBusiness;
}

const initialStateData = {
  id: 0,
  street: "",
  city: "",
  latitude: 0,
  longitude: 0,
  email: "",
  facebook: "",
  instagram: "",
  meta_description: "",
  meta_keywords: "",
  telephones: [],

  created_at: "",
  updated_at: "",
}

interface IUpdateDataAsync {
  data: IBusiness;
  token: string;
}
interface IUpdatePhoneAsync {
  id:number;
  data: ITelephone;
  token: string;
}

const initialState: IInitialState = {
  state: "INACTIVE",
  data: initialStateData,
  dataTelephone: initialStateData,

};

export const getDataAsync = createAsyncThunk("data/getData", async () => {
  const response = await BusinessService.getBusiness();

  return response;
});

export const updateDataAsync = createAsyncThunk(
  "data/updateData",
  ({ data, token }: IUpdateDataAsync) => {
    const result = BusinessService.upadateBusiness(data, token);
    
    return result;
  }
);

export const updatePhoneAsync = createAsyncThunk(
  "data/updatePhone",
  ({ id, data, token }: IUpdatePhoneAsync) => {
    const result = BusinessService.upadateTelephone(id, data, token);
    
    return result;
  }
);


const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    cleanAllData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /* GET DATA */
      .addCase(getDataAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(getDataAsync.fulfilled, (state, action) => {
        const dataLoaded = action.payload;
        state.state = "INACTIVE";
        state.data = dataLoaded;
        state.dataTelephone = dataLoaded;

      })
      .addCase(getDataAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* UPDATE DATA */
      .addCase(updateDataAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(updateDataAsync.fulfilled, (state, action) => {
        const dataUpdated = action.payload;
        state.state = "INACTIVE";
        state.data = dataUpdated;
      })
      .addCase(updateDataAsync.rejected, (state) => {
        state.state = "FAIL";
      })

            /* UPDATE TELEPHONE */
            .addCase(updatePhoneAsync.pending, (state) => {
              state.state = "LOADING";
            })
            .addCase(updatePhoneAsync.fulfilled, (state, action) => {              
              state.state = "INACTIVE";
            })
            .addCase(updatePhoneAsync.rejected, (state) => {
              state.state = "FAIL";
            });
  },
});

export const { cleanAllData } = dataSlice.actions;

export const getDataFromApi = () => (dispatch: AppDispatch) => {
  dispatch(cleanAllData());
  dispatch(getDataAsync());
};

export const updateDataFromApi = (data:IBusiness, token: string) => (dispatch: AppDispatch) => {
  dispatch(updateDataAsync({data:data, token:token}))
};

export const updatePhoneFromApi = (id: number, data:ITelephone, token: string) => (dispatch: AppDispatch) => {
  dispatch(updatePhoneAsync({id:id, data:data, token:token}))
};

export const getDataState = (state: RootState) => state.data;

export default dataSlice.reducer;
