import { FC, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

type Props = {
  text: string;
  state: "LOADING" | "INACTIVE" | "FAIL";
  disable?: boolean;
  contact?: boolean;
  size?: "small" | "medium" | "large";
};

const LoadingButtonComponent: FC<Props> = ({
  text,
  contact,
  state,
  disable,
  size,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked && state !== "LOADING") {
      setIsClicked(false);
    }
  }, [state]);

  return (
    <LoadingButton
      size={size ? size : "small"}
      color="secondary"
      type="submit"
      onClick={() => setIsClicked(true)}
      loading={isClicked && state === "LOADING"}
      loadingPosition="start"
      startIcon={!contact && <FontAwesomeIcon icon={faFloppyDisk} />}
      endIcon={contact && <FontAwesomeIcon icon={faPaperPlane} />}
      variant="contained"
      disabled={disable}
    >
      {text}
    </LoadingButton>
  );
};

export default LoadingButtonComponent;
