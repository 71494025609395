import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { PaginationComponent } from "..";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCategoriesFromApi } from "../../store/slices/categories.slice";
import { getProductsFromApi } from "../../store/slices/products.slice";
import SectionFiltersMobile from "../SectionFilters/SectionFiltersMobile/SectionFiltersMobile.component";
import styles from "./PaginationLayout.module.css";

const PaginationLayout = ({
  children,
  alternative,
}: {
  children: JSX.Element;
  alternative?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const qtyPage = useAppSelector((state) => state.products.qtyPage);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("tipo");
  const brandParam = searchParams.get("marca");
  const pageParam = searchParams.get("page");

  const pageTopRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const category = categoryParam
      ? parseInt(categoryParam?.split("_")[0])
      : null;
    const brand = brandParam ? parseInt(brandParam?.split("_")[0]) : null;
    const page = pageParam ? parseInt(pageParam) : 1;

    dispatch(
      getProductsFromApi({
        byBrand: brand,
        byCategory: category,
        page: page,
      })
    );

    if (pageTopRef.current && page !== 1) {
      pageTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [categoryParam, brandParam, pageParam]);

  useEffect(() => {
    dispatch(getCategoriesFromApi());
  }, []);

  return (
    <main className={styles.container}>
      <div
        ref={pageTopRef}
        className={alternative ? styles.altRef : styles.ref}
      ></div>
      <div className={styles.content}>
        <SectionFiltersMobile />
        <div className={styles.sectionCards}>
          {children}
          {qtyPage > 1 ? <PaginationComponent /> : <></>}
        </div>
      </div>
    </main>
  );
};

export default PaginationLayout;
