import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISliders } from "../../types/ISliders.type";
import SlidersService from "../services/slices.endpoints";
import { AppDispatch, RootState } from "../store";

interface IInitialState {
  state: "LOADING" | "INACTIVE" | "FAIL";
  sliders: {
    mobile: ISliders[];
    tablet: ISliders[];
    desktop: ISliders[];
  };
}

const initialState: IInitialState = {
  state: "INACTIVE",
  sliders: {
    mobile: [],
    tablet: [],
    desktop: [],
  },
};

export const getSlidersAsync = createAsyncThunk(
  "slider/getSliders",
  async () => {
    const response = await SlidersService.getSliders();
    return response;
  }
);

export const postNewSliderAsync = createAsyncThunk(
  "slider/postNewSlider",
  ({ slider, token }: { slider: any; token: string }) => {
    const result = SlidersService.postNewSliders(slider, token);

    return result;
  }
);

export const deleteSliderAsync = createAsyncThunk(
  "slider/deleteSlider",
  ({ slider, token }: { slider: ISliders; token: string }) => {
    const result = SlidersService.deleteSliders(slider.id, token);
    return result;
  }
);

const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    cleanAllSliders: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /* GET SLIDERS */
      .addCase(getSlidersAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(getSlidersAsync.fulfilled, (state, action) => {
        const sliders: any[] = action.payload;

        const mobile = sliders.filter((slider) => slider.tag === "mobile");
        const tablet = sliders.filter((slider) => slider.tag === "tablet");
        const desktop = sliders.filter((slider) => slider.tag === "desktop");

        state.state = "INACTIVE";
        state.sliders.mobile = mobile;
        state.sliders.tablet = tablet;
        state.sliders.desktop = desktop;
      })
      .addCase(getSlidersAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* POST SLIDERS */
      .addCase(postNewSliderAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(postNewSliderAsync.fulfilled, (state, action) => {
        const sliders: ISliders = action.payload;
        const tag: string = action.payload.tag;

        state.state = "INACTIVE";

        if (tag === "mobile") {
          state.sliders.mobile = [...state.sliders.mobile, sliders];
        } else if (tag === "tablet") {
          state.sliders.tablet = [...state.sliders.tablet, sliders];
        } else {
          state.sliders.desktop = [...state.sliders.desktop, sliders];
        }
      })
      .addCase(postNewSliderAsync.rejected, (state) => {
        state.state = "FAIL";
      })

      /* DELETE SLIDER */
      .addCase(deleteSliderAsync.pending, (state) => {
        state.state = "LOADING";
      })
      .addCase(deleteSliderAsync.fulfilled, (state, action) => {
        const sliders: ISliders = action.payload;
        const tag: string = action.payload.tag;

        state.state = "INACTIVE";

        if (tag === "mobile") {
          state.sliders.mobile = state.sliders.mobile.filter(
            (slider) => slider.id !== sliders.id
          );
        } else if (tag === "tablet") {
          state.sliders.tablet = state.sliders.tablet.filter(
            (slider) => slider.id !== sliders.id
          );
        } else {
          state.sliders.desktop = state.sliders.desktop.filter(
            (slider) => slider.id !== sliders.id
          );
        }
      })
      .addCase(deleteSliderAsync.rejected, (state) => {
        state.state = "FAIL";
      });
  },
});

export const { cleanAllSliders } = sliderSlice.actions;

export const getSlidersApi = () => (dispatch: AppDispatch) => {
  dispatch(cleanAllSliders());
  dispatch(getSlidersAsync());
};

/** ADMIN **/
export const postNewSliderApi =
  (slider: any, token: string) => (dispatch: AppDispatch) => {
    dispatch(postNewSliderAsync({ slider, token }));
  };

export const deleteSliderApi =
  (slider: ISliders, token: string) => (dispatch: AppDispatch) => {
    dispatch(deleteSliderAsync({ slider, token }));
  };

export const getSliderState = (state: RootState) => state.sliders;

export default sliderSlice.reducer;
