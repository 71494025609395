import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, useState } from "react";
import styles from "./Input.module.css";

type InputProps = {
  name: string;
  onChange: (e: ChangeEvent<any>) => void;
  type?: "text" | "number" | "password";
  label?: string;
  placeholder?: string;
  value?: string | number;
  deactivatable?: boolean;
};

const Input: FC<InputProps> = ({
  name,
  onChange,
  type = "text",
  label,
  placeholder,
  value,
  deactivatable = false,
}) => {
  const [isInputDiseabled, setIsInputDiseabled] = useState(deactivatable);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div className={styles.inputContainer}>
        <input
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          disabled={deactivatable && isInputDiseabled}
          className={styles.input}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className={styles.button}
          >
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </button>
        )}

        <div className={styles.checkbox}>
          {deactivatable && (
            <input
              type="checkbox"
              onChange={() => setIsInputDiseabled(!isInputDiseabled)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Input;
