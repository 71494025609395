import axios, { AxiosResponse } from "axios";
import { ICategory } from "../../types/ICategory.type";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;


interface IHeaders {
  headers: { "Content-Type": string; Authorization: string };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
};


const getAllCategories: () => Promise<ICategory[]> = async () => {
  const response = await axios.get(`${API_ENDPOINT}api/categories`);
  return response.data;
};

const postNewCategory = async (category: ICategory, token: string) => {
    const config: IHeaders = configHeaders(token);
  
  const res = await axios
    .post(`${API_ENDPOINT}admin/categories`, { category: category }, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });

   
  return res;
};

const upadateCategory = async (category: ICategory, token: string) => {
  const config: IHeaders = configHeaders(token);

  const res = await axios
    .put(`${API_ENDPOINT}admin/categories/${category.id}`, category, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data.result;
    });
  return res;
};

const deleteCategory = async (id: number, token: string) => {
  const config: IHeaders = configHeaders(token);

  await axios.delete(`${API_ENDPOINT}admin/categories/${id}`, config);
  return id;
};

const CategoriesService = {
  getAllCategories,
  postNewCategory,
  upadateCategory,
  deleteCategory,
};
export default CategoriesService;
