import { useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";

import "./SliderThumbnail.css";

import { FreeMode, Navigation, Thumbs } from "swiper";
import { IImage } from "../../types/IProduct.type";
import { useResize } from "../hooks/useResize";

export default function SliderThumbnail({ images }: { images: IImage[] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const isPhone = useResize();

  const SwipperSlide = () => {
    return images.map((image) => {
      return (
        <SwiperSlide className="thumbItem">
          <img className="thumbItem" src={image.image.large.url} />
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="sliderThumbContainer">
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiperThumbnail2"
      >
        {SwipperSlide()}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        direction={isPhone <= 765 ? "horizontal" : "vertical"}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiperThumbnail"
      >
        {SwipperSlide()}
      </Swiper>
    </div>
  );
}
