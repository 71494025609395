import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getDataFromApi } from "../../store/slices/business.slice";
import logo from "../../assets/logoTransparente.png";
import styles from "./Map.module.css";

const MapComponent = () => {
  const dispatch = useAppDispatch();

  const { data, state } = useAppSelector((state) => state.data);

  useEffect(() => {
    if (data.latitude === 0) {
      dispatch(getDataFromApi());
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.75413995533!2d${data.longitude}!3d${data.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab6225ac483d%3A0x11f76d064ab782b!2sGrupo%20R%20%26%20F!5e0!3m2!1sen!2sar!4v1665496391614!5m2!1sen!2sar`}
        width="100%"
        height="100%"
        // style="border:0;"
        // allowfullscreen=""
        loading="lazy"
        style={{ border: "none", margin: "0px", padding: "0px" }}
        // referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <img src={logo} alt="logo" className={styles.image} />
    </div>
  );
};

export default MapComponent;
