import axios, { AxiosResponse } from "axios";
import { IBrandPost } from "../../types/IBrand.type";
import { IBrandCategory } from "../slices/brands.slice";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;


interface IHeaders {
  headers: { "Content-Type": string; Authorization: string };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const getAllBrands: () => Promise<IBrandCategory[]> = async () => {
  const response = await axios.get(`${API_ENDPOINT}api/brands`);
  return response.data;
};

const postNewBrand = async (brand: IBrandPost, category_id:number, token: string) => {
    const config: IHeaders = configHeaders(token);
  
  const res = await axios
    .post(`${API_ENDPOINT}admin/categories/${category_id}/brands`, { brand: brand }, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
  return res;
};

const upadateBrand = async (brand: IBrandPost, category_id:number,  token: string) => {
  const config: IHeaders = configHeaders(token);

  const res = await axios
    .put(`${API_ENDPOINT}admin/categories/${category_id}/brands/${brand.id}`, brand, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
    
  return res;
};

const deleteBrand = async (id: number, category_id:number, token: string) => {
  const config: IHeaders = configHeaders(token);

  await axios.delete(`${API_ENDPOINT}admin/categories/${category_id}/brands/${id}`, config);
  return id;
};

const BrandService = {
  getAllBrands,
  postNewBrand,
  upadateBrand,
  deleteBrand,
};
export default BrandService;
