import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

const deleteModal = (message:string) => {
  return Swal.fire({
    title: `${message}`,
    text: "Se eliminará definitivamente",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Borrar",
    cancelButtonText: "Candelar",
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};

// const deleteModalProduct = () => {
//   return Swal.fire({
//     title: "¿Desea borrar este producto?",
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Borrar",
//     cancelButtonText: "Candelar",
//   }).then((result) => {
//     if (result.isConfirmed) {
//       return true;
//     } else {
//       return false;
//     }
//   });
// };

const Modals = {
  deleteModal,
  // deleteModalProduct,
};
export default Modals;
