import axios, { AxiosResponse } from "axios";
import { IProductPost } from "../../types/IProductPost.type";
const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = process.env;

interface IHeaders {
  headers: { "Content-Type": string; Authorization: string };
}

const configHeaders = (access_token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
};

const getAllProduct = async (
  brand_id: number | null,
  category_id: number | null,
  page: number | null
) => {
  let query = "";

  if (category_id !== null && !isNaN(category_id)) {
    query = `&category_id=${category_id}`;
  }
  //  if(category_id !== null && !isNaN(category_id) && brand_id !== null && !isNaN(brand_id) ){
  //    query = `&category_id=${category_id}&brand_id=${brand_id}`;
  //  }
  if (brand_id !== null && !isNaN(brand_id)) {
    query = `&brand_id=${brand_id}`;
  }

  const response = await axios.get(
    `${API_ENDPOINT}api/products?page=${page}${query}`
  );

  return response.data;
};

const getProductById = async (id: number) => {
  const response = await axios.get(`${API_ENDPOINT}api/products/${id}`);

  return response.data;
};

const postNewProduct = async (product: IProductPost, token: string) => {
  const config: IHeaders = configHeaders(token);

  const res = await axios
    .post(`${API_ENDPOINT}admin/products`, { product: product }, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
  return res;
};

const upadateProduct = async (product: IProductPost, token: string) => {
  const config: IHeaders = configHeaders(token);
  const res = await axios
    .put(`${API_ENDPOINT}admin/products/${product.id}`, product, config)
    .then(({ data }: AxiosResponse<any>) => {
      return data;
    });
  return res;
};

const deleteProduct = async (id: number, token: string) => {
  const config: IHeaders = configHeaders(token);

  await axios.delete(`${API_ENDPOINT}admin/products/${id}`, config);
  return id;
};

const ProductService = {
  getAllProduct,
  getProductById,
  postNewProduct,
  deleteProduct,
  upadateProduct,
};
export default ProductService;
