import { FC } from "react";
import { Button } from "@mui/material";

type Props = {
  children: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  variant?: "outlined" | "text" | "contained";
  color?:
    | "inherit"
    | "warning"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info";
};

const GeneralButton: FC<Props> = ({
  children,
  disabled,
  onClick,
  variant,
  color,
}) => {
  function handleClick() {
    onClick();
  }

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
      size="small"
      variant={variant ? variant : "contained"}
      color={color ? color : "success"}
    >
      {children}
    </Button>
  );
};

export default GeneralButton;
