import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lazy, Suspense, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFileName } from "../../../utils/getFileName";
import styles from "./LoadImage.module.css";
import "sweetalert2/dist/sweetalert2.min.css";
import Modals from "../../../utils/modal";
import PhotoService from "../../../store/services/photos.endpoints";
import { IImage } from "../../../types/IProduct.type";
import { getIdImageFile } from "../../../utils/getIdImageFile";
import { addImage } from "../../../store/slices/products.slice";

const Image = lazy(() => import("./Image.component"));

export type ImageType = {
  id: number;
  product_id: number;
  url: string;
  urlthumbnail: string;
};

export const LoadImage = ({
  title,
  productID,
  images,
  cleanForm,
}: {
  title?: string;
  productID: number;
  images?: IImage[];
  cleanForm?: boolean;
}) => {
  const [fileToSave, setFileToSave] = useState<any>([]);

  const [imagesLists, setImagesLists] = useState<ImageType[]>([]);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.authAdmin.token);

  useEffect(() => {
    setImagesLists([]);
  }, [cleanForm]);

  const loadFiles = (file: any) => {
    const f = new FormData();
    f.append("image", file[0]);

    setFileToSave(f);
  };

  const sendFiles = async () => {
    const response = PhotoService.postNewPhoto(fileToSave, token, productID);
    response.then((response) => {
      if (response.status === 200) {
        setImagesLists([
          ...imagesLists,
          {
            id: response.data.id,
            product_id: response.data.product_id,
            url: getFileName(response.data.image.url),
            urlthumbnail: response.data.image.thumb.url,
          },
        ]);

        dispatch(
          addImage({
            id: productID,
            image: response.data,
          })
        );
      }
    });
  };

  const deleteFiles = (id: number, product_id: number) => {
    Modals.deleteModal("¿Desea elminar el archivo?").then((response) => {
      if (response === true) {
        const response = PhotoService.deletePhoto(id, product_id, token);
        response.then((response) => {
          const imageListAfterDelete = imagesLists.filter((file: ImageType) => {
            return file.id !== id;
          });
          setImagesLists(imageListAfterDelete);
        });
      }
    });
  };

  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}

      <form encType="multipart/form-data" action="" className={styles.form}>
        <input
          type="file"
          name="files"
          multiple
          onChange={(e) => loadFiles(e.target.files)}
          className={styles.fieldInput}
          disabled={productID === 0}
        />
        <FontAwesomeIcon
          icon={faFloppyDisk}
          onClick={() => sendFiles()}
          className={styles.icon}
        />
      </form>
      <div className={styles.listStyle}>
        {images?.map((image, index) => {
          return (
            <div key={`image_${index}`}>
              <Suspense fallback={<div>...</div>}>
                <Image
                  image={image.image.thumb.url}
                  deleteFiles={() =>
                    deleteFiles(
                      getIdImageFile(image.image.thumb.url),
                      productID
                    )
                  }
                />
              </Suspense>
            </div>
          );
        })}
        {imagesLists.map((image, index) => {
          return (
            <div key={`image_${index}`}>
              <Suspense fallback={<div>...</div>}>
                <Image
                  image={image.urlthumbnail}
                  deleteFiles={() => deleteFiles(image.id, image.product_id)}
                />
              </Suspense>
            </div>
          );
        })}
      </div>
    </div>
  );
};
