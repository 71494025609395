import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dataReducer from "./slices/business.slice";
import categoriesReducer from "./slices/categories.slice";
import authAdminReducer from "./slices/authAdmin.slice";
import productReducer from "./slices/products.slice";
import brandsReducer from "./slices/brands.slice";
import slidersReducer from "./slices/sliders.slice";


const rootReducer = combineReducers({
  data: dataReducer,
  categories: categoriesReducer,
  authAdmin: authAdminReducer,
  brands: brandsReducer,
  sliders: slidersReducer,


  products: productReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
