import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LoadSlice.module.css";

export const Image = ({
  deleteFiles,
  image,
}: {
  deleteFiles: () => void;
  image: string;
}) => {
  return (
    <div className={styles.fileSaved}>
      <img src={image} alt={image} className={styles.image}></img>
      <button className={styles.fileIcons} onClick={deleteFiles}>
        <FontAwesomeIcon icon={faTrashCan} className={styles.iconTrash} />
      </button>
    </div>
  );
};
