import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { FC } from "react";
import styles from "./ButtonWhatsapp.module.css";

type ButtonProps = {
  title: string;
  number: string;
};

const ButtonWhatsapp: FC<ButtonProps> = ({ title, number }) => {
  return (
    <Button
      type="button"
      className={styles.button}
      // sx={{ backgroundColor: "#f1f1f1" }}
    >
      <a
        href={`https://wa.me/${number}`}
        target="_blanck"
        rel="noopener noreferrer"
        className={styles.containerData}
      >
        <FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />
        <p className={styles.contentData}>
          <span className={styles.title}>{title}</span>
          <span className={styles.number}>{number}</span>
        </p>
      </a>
    </Button>
  );
};

export default ButtonWhatsapp;
