import { FC, Fragment } from "react";
import styles from "./SectionFiltersMobile.module.css";
import { FilterType } from "./SectionFiltersMobile.component";
import { ICategory } from "../../../types/ICategory.type";
import { Divider, ListItem, ListItemButton, ListItemText } from "@mui/material";

type FilterProps = {
  category: ICategory;
  filter: FilterType;
  handleCategorySelected: (id: number, name: string) => void;
  handleBrandSelected: (
    id: number,
    name: string,
    categoryId: number,
    categoryName: string
  ) => void;
};

export const Filter: FC<FilterProps> = ({
  category,
  filter,
  handleCategorySelected,
  handleBrandSelected,
}) => {
  return (
    <Fragment key={`category_${category.id}`}>
      <ListItem key={category.id} disablePadding>
        <ListItemButton
          onClick={() => handleCategorySelected(category.id, category.name)}
          sx={{
            borderLeft:
              filter.category.id === category.id && filter.brand.id === null
                ? "4px solid #000"
                : "none",
            backgroundColor:
              filter.category.id === category.id && filter.brand.id === null
                ? "rgb(250, 250, 250)"
                : "transparent",
          }}
        >
          <ListItemText
            primary={category.name}
            sx={{
              textTransform: "uppercase",
            }}
          />
        </ListItemButton>
      </ListItem>
      <div className={styles.brandsContainer}>
        {category?.brands.map((brand) => (
          <ListItem key={`brand_${brand.id}`} disablePadding>
            <ListItemButton
              sx={{
                paddingTop: "5px",
                paddingBottom: "5px",
                borderLeft:
                  filter.brand.id === brand.id ? "4px solid #000" : "none",
                backgroundColor:
                  filter.brand.id === brand.id
                    ? "rgb(250, 250, 250)"
                    : "transparent",
              }}
              onClick={() =>
                handleBrandSelected(
                  brand.id,
                  brand.name,
                  category.id,
                  category.name
                )
              }
            >
              <ListItemText secondary={brand.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
      </div>
    </Fragment>
  );
};
